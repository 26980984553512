<template>
  <el-container class="UpdateGames">
    <el-header style="display: flex; justify-content: space-between; align-items: center;">
      <h1>游戏更新记录</h1>
      <el-button type="primary" @click="toggleDialog">添加更新记录</el-button>
    </el-header>
    <el-main>
      <el-input 
        v-model="searchQuery" 
        placeholder="搜索 AppID、游戏 ID" 
        @input="onSearch"
        clearable
        style="margin-bottom: 20px;"
      ></el-input>
      <el-table
        :data="records && records.length ? records : []"
        v-if="records && records.length > 0"
        style="width: 100%;"
        :default-sort="{ prop: 'updateTime', order: 'descending' }"
      >
        <el-table-column prop="appidToken" label="AppID Token" width="300"></el-table-column>
        <el-table-column prop="gameid" label="游戏 ID" width="120"></el-table-column>
        <el-table-column prop="types" label="更新状态" width="150">
          <template #default="scope">
            <el-tag :type="getTagType(scope.row.types)">
              {{ getTagLabel(scope.row.types) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" sortable width="180"></el-table-column>
      </el-table>
      <div v-else>
        <el-empty description="没有记录可显示"></el-empty>
      </div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="totalPages * pageSize"
        :page-size="pageSize"
        v-model:current-page="currentPage"
        @current-change="onPageChange"
        style="margin-top: 20px; text-align: center;"
      ></el-pagination>
    </el-main>

    <!-- Dialog 模态框 -->
    <el-dialog title="添加游戏更新记录" v-model="showDialog" width="30%" @close="resetDialog">
      <el-form ref="updateForm" :model="formData" label-width="120px">
        <el-form-item label="选择游戏">
          <el-select v-model="formData.gameid" multiple placeholder="选择游戏" filterable clearable>
            <el-option
              v-for="game in games"
              :key="game.gameid"
              :label="game.title"
              :value="game.gameid"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="更新时间">
          <el-date-picker 
            v-model="formData.updateTime"
            type="datetime"
            placeholder="选择更新时间"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取消</el-button>
        <el-button type="primary" @click="submitUpdate">提交</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import { ElMessage } from 'element-plus';
import axios from '../axios';
import dayjs from 'dayjs';

export default {
  data() {
    return {
      showDialog: false, 
      formData: {
        updateTime: '', // 用户选择的更新时间
        gameid: [], // 选择的游戏 ID 列表，支持多选
      },
      records: [],
      searchQuery: '',
      games: [], // 游戏列表
      currentPage: 1,
      pageSize: 10,
      totalPages: 0,
    };
  },
  methods: {
    // 显示Dialog
    toggleDialog() {
      this.showDialog = true;
    },
    // 重置表单数据
    resetDialog() {
      this.formData.updateTime = '';
      this.formData.gameid = [];
    },
    // 提交数据到后端
    async submitUpdate() {
      try {
        const token = localStorage.getItem('user-token');
        
        // 确保时间格式为 'yyyy-MM-dd HH:mm:ss'
        const formattedTime = dayjs(this.formData.updateTime).format('YYYY-MM-DD HH:mm:ss');
        
        // 将选中的 gameid 数组转换为逗号分隔的字符串
        const gameids = this.formData.gameid.join(',');

        const response = await axios.post('/index.php?action=addUpdateGameRecords', {
          token,
          updateTime: formattedTime, // 使用格式化后的时间
          gameid: gameids // 提交多个 gameid，用逗号分隔
        });

        if (response.data.success) {
          ElMessage.success('更新记录已添加');
          this.showDialog = false; // 隐藏Dialog
          this.resetDialog(); // 重置表单
          this.fetchRecords();
        } else {
          ElMessage.error('添加更新记录失败');
        }
      } catch (error) {
        console.error('Error submitting update:', error);
        ElMessage.error('提交更新时发生错误');
      }
    },
    // 获取游戏列表
    async fetchGames() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=getGames_make', {
          token
        });
        this.games = Array.isArray(response.data.games) ? response.data.games : [];
      } catch (error) {
        console.error('Error fetching games:', error);
        ElMessage.error('获取游戏列表时发生错误');
      }
    },
    // 处理分页变化
    onPageChange(page) {
      this.currentPage = page;
      this.fetchRecords();
    },
    // 获取游戏记录数据
    async fetchRecords() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=getUpdateGamesRecords', {
          token,
          query: this.searchQuery
        });
        this.records = Array.isArray(response.data.records) ? response.data.records : [];
        this.totalPages = Math.ceil(this.records.length / this.pageSize);
      } catch (error) {
        console.error('Error fetching records:', error);
        ElMessage.error('获取记录时发生错误');
      }
    },
    // 根据 types 获取标签颜色
    getTagType(types) {
      switch (types) {
        case "0":
          return 'warning'; // 待更新
        case "1":
          return 'success'; // 更新成功
        case "2":
          return 'danger'; // 错误或验证码问题
        default:
          return ''; // 默认标签类型
      }
    },
    // 根据 types 获取标签内容
    getTagLabel(types) {
      switch (types) {
        case "0":
          return '待更新';
        case "1":
          return '更新成功';
        case "2":
          return '错误或验证码';
        default:
          return '未知状态';
      }
    }
  },
  created() {
    this.fetchRecords(); // 页面加载时获取记录
    this.fetchGames(); // 页面加载时获取游戏列表
  }
};
</script>

<style scoped>
.UpdateGames {
  padding: 20px;
}

.el-table th, .el-table td {
  white-space: nowrap;
}

.el-table {
  width: 100%;
}

.dialog-footer {
  text-align: right;
}

.el-header {
  padding: 20px 0;
}

.el-input {
  margin-bottom: 20px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
</style>
