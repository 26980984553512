<template>
  <div class="SalesOverview">
    <!-- 顶部选择栏 -->
    <el-row :gutter="20" class="filters">
      <el-col :span="6">
        <el-form-item label="选择经销商">
          <el-select v-model="selectedDealer" placeholder="请选择经销商" @change="fetchData" class="custom-select">
            <el-option value="all" label="全部"></el-option>
            <el-option v-for="dealer in dealers" :key="dealer.username" :label="dealer.username" :value="dealer.username"></el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="10">
        <el-form-item label="选择时间">
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            range-separator="至"
            format="YYYY-MM-DD"
            class="custom-date-picker"
          />
        </el-form-item>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="fetchData" class="fetch-button">查询</el-button>
      </el-col>
    </el-row>

    <!-- 数据展示区域 -->
    <el-row :gutter="40" class="stats-container centered">
      <el-col :span="15">
        <el-card class="stat-block">
          <div class="amount-container">
            <h2 class="main-amount">{{ formatAmount(total_price) }}</h2>
            <span>|</span>
            <h2 class="super-amount">{{ formatAmount(total_super_price) }}</h2>
          </div>
          <p class="main-title"><b>累计总交易额 / 终极版总交易额</b></p>
          <ul class="stats-list">
            <li><i class="el-icon-s-order"></i> 累计成交笔数: {{ count }} - 累计退款笔数: {{ refund_count }} = 实际成交笔数: {{ final_count }}</li>
            <li><i class="el-icon-s-data"></i> 总计退款额: {{ formatAmount(refund_total_price) }} 元</li>
            <li><i class="el-icon-s-platform"></i> 终极版累计退款额: {{ formatAmount(refund_total_super_price) }} 元</li>
          </ul>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from '../axios'; // 引入axios实例
import { ElMessage } from 'element-plus'; // 引入Element Plus的消息提示

export default {
  name: 'SalesOverview',
  data() {
    return {
      selectedDealer: 'all', // 选择的经销商
      dealers: [], // 经销商列表
      dateRange: [], // 时间选择的范围
      total_price: 0.00, // 总交易额
      total_super_price: 0.00, // 终极版交易额
      count: 0, // 总成交笔数
      refund_total_price: 0.00, // 总计退款额
      refund_total_super_price: 0.00, // 终极版退款额
      refund_count: 0, // 总退款笔数
      final_count: 0 // 实际成交笔数
    };
  },
  mounted() {
    this.fetchDealers(); // 加载经销商列表
    this.fetchData(); // 加载数据
  },
  methods: {
    // 获取经销商列表
    async fetchDealers() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=getSellerForSales', { token });
        if (response.data.success) {
          this.dealers = response.data.dealers;
        } else {
          ElMessage.error('无法获取经销商列表');
        }
      } catch (error) {
        console.error('Error fetching dealers:', error);
        ElMessage.error('获取经销商列表失败，请稍后重试');
      }
    },
    // 查询销售数据
    async fetchData() {
      try {
        const token = localStorage.getItem('user-token');
        const params = {
          Seller_Name: this.selectedDealer,
          Sales_DateTime: this.formatDateRange(this.dateRange) // 格式化时间区间
        };
        const response = await axios.post('/index.php?action=getSellerSales', { token, ...params });

        if (response.data.success) {
          const data = response.data;
          // 更新 Vue 的数据
          this.total_price = data.total_price;
          this.total_super_price = data.total_super_price;
          this.count = data.count;
          this.refund_total_price = data.refund_total_price;
          this.refund_total_super_price = data.refund_total_super_price;
          this.refund_count = data.refund_count;
          this.final_count = data.final_count;
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error fetching sales data:', error);
        ElMessage.error('查询失败，请稍后重试');
      }
    },
    // 格式化日期区间为 '2024-08-25 00:00:00 to 2024-08-25 23:59:59' 的格式
    formatDateRange(dateRange) {
      if (dateRange.length === 2) {
        const start = new Date(dateRange[0]).toLocaleString();
        const end = new Date(dateRange[1]).toLocaleString();
        return `${start} to ${end}`;
      } else {
        return ''; // 没有选择时间时返回空
      }
    },
    // 格式化金额，保留两位小数
    formatAmount(amount) {
      return amount ? parseFloat(amount).toFixed(2) : '0.00';
    }
  }
};
</script>

<style scoped>
.SalesOverview {
  padding: 0;
  margin: 0;
  background-color: #fff;
}

.filters {
  margin-bottom: 30px;
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.centered {
  display: flex;
  justify-content: center;
}

.fetch-button {
  background-image: linear-gradient(to right, #1a9eec, #1f2937);
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 25px;
  transition: all 0.3s ease;
}

.fetch-button:hover {
  background-image: linear-gradient(to right, #1f2937, #4a90e2);
}

.stat-block {
  background-color: white; /* 更纯净的白色背景 */
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  max-width: 700px;
  margin: 0 auto;
}

.stat-block .main-title {
  font-size: 22px;
  color: #34495e;
  margin-bottom: 25px;
  font-weight: 600;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.stat-block .amount-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.stat-block h2 {
  font-size: 40px;
  color: #2c3e50;
  font-weight: 700;
}

.stat-block .super-amount {
  font-size: 40px;
  color: #2c3e50;
  font-weight: 700;
}

.stat-block ul {
  list-style-type: none;
  padding: 0;
  font-size: 20px;
  line-height: 1.8;
  color: #2c3e50;
}

.stat-block ul li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.stat-block ul li i {
  margin-right: 12px;
  font-size: 22px;
  color: #34d4d1;
}

.custom-select .el-input__inner,
.custom-date-picker .el-input__inner {
  border-radius: 25px;
  padding: 12px 20px;
}

</style>
