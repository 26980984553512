<template>
  <div>
    <div v-if="!$route.meta.hideSidebar">
      <el-row class="tac">
        <el-col :span="4" class="sidebar-menu">
          <el-menu
            :default-active="activeMenu"
            class="el-menu-vertical-demo"
            background-color="#1F2937"
            text-color="#fff"
            active-text-color="#19A9FD"
            unique-opened
            router
            @open="handleOpen"
            @close="handleClose"
          >
          <div class="user">
            <div class="user-div">
              <div class="user-phone">
                <p>{{ username }}</p>
              </div>
              <div v-if="owner === '0'" class="user-vip">
                管理员
              </div>
              <div v-else class="user-vip">
                经销商
              </div>
            </div>
          </div>
            <el-sub-menu index="dashboard">
              <template #title>
                <el-icon><location /></el-icon>
                <span>仪表盘</span>
              </template>
              <el-menu-item index="/">
                <el-icon><icon-menu /></el-icon>
                首页
              </el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="codeManagement">
              <template #title>
                <el-icon><document /></el-icon>
                <span>兑换码管理</span>
              </template>
              <el-menu-item index="/generateCode">
                <el-icon><icon-menu /></el-icon>
                生成兑换码
              </el-menu-item>
              <el-menu-item index="/generateCodeZS">
                <el-icon><icon-menu /></el-icon>
                赠送系统
              </el-menu-item>
              <el-menu-item index="/codeRecords">
                <el-icon><icon-menu /></el-icon>
                兑换码记录
              </el-menu-item>
            </el-sub-menu>

            <el-sub-menu  v-if="owner === '0'" index="gameManagement">
              <template #title>
                <el-icon><Memo /></el-icon>
                <span>游戏管理</span>
              </template>
              <el-menu-item v-if="owner === '0'" index="/gameList">
                <el-icon><icon-menu /></el-icon>
                游戏列表
              </el-menu-item>
              <el-menu-item v-if="owner === '0'" index="/gameUpdate">
                <el-icon><icon-menu /></el-icon>
                更新游戏
              </el-menu-item>
              <el-menu-item v-if="owner === '0'" index="/newGame">
                <el-icon><icon-menu /></el-icon>
                发布新游戏
              </el-menu-item>
              <el-menu-item v-if="owner === '0'" index="/depotsManagement">
                <el-icon><icon-menu /></el-icon>
                DepotsID管理
              </el-menu-item>
              <!-- <el-menu-item v-if="owner === '0'" index="/inventoryUpload">
                <el-icon><icon-menu /></el-icon>
                清单上传
              </el-menu-item> -->
            </el-sub-menu>

            <el-sub-menu index="userManagement">
              <template #title>
                <el-icon><user /></el-icon>
                <span>用户管理</span>
              </template>
              <el-menu-item index="/registeredUsers">
                <el-icon><icon-menu /></el-icon>
                已注册用户
              </el-menu-item>
              <el-menu-item index="/order" v-if="owner === '0'">
                <el-icon><icon-menu /></el-icon>
                订单管理
              </el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="adminManagement" v-if="owner === '0'">
              <template #title>
                <el-icon><setting /></el-icon>
                <span>后台管理</span>
              </template>
              <el-menu-item index="/distributors">
                <el-icon><icon-menu /></el-icon>
                经销商列表
              </el-menu-item>
              <el-menu-item index="/salesOverview">
                <el-icon><icon-menu /></el-icon>
                经销商经营状况
              </el-menu-item>
              <el-menu-item index="/addDistributor">
                <el-icon><icon-menu /></el-icon>
                添加经销商
              </el-menu-item>
              <el-menu-item index="/announcements">
                <el-icon><icon-menu /></el-icon>
                发布公告
              </el-menu-item>
              <el-menu-item index="/dashboard">
                <el-icon><icon-menu /></el-icon>
                更多功能
              </el-menu-item>
              <el-menu-item index="/codeSettings">
                <el-icon><icon-menu /></el-icon>
                权限组管理
              </el-menu-item>
            </el-sub-menu>
          </el-menu>
        </el-col>

        <el-col :span="20" class="righ">
          <router-view />
        </el-col>
      </el-row>
    </div>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import axios from './axios';
import {
  Document,
  Menu as IconMenu,
  Location,
  Setting,
  SGrid,
  User
} from '@element-plus/icons-vue';

export default {
  name: 'App',
  setup() {
    const store = useStore();
    const route = useRoute();

    const username = computed(() => store.state.user.username);
    const owner = computed(() => store.state.user.owner);

    const activeMenu = computed(() => route.path);

    return {
      username,
      owner,
      activeMenu,
      Document,
      IconMenu,
      Location,
      Setting,
      SGrid,
      User
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    checkAuthentication() {
      const token = localStorage.getItem('user-token');
      if (token) {
        axios.post('/index.php?action=check', { token })
          .then(() => {
            this.isAuthenticated = true;
          })
          .catch(() => {
            this.handleLogout();
          });
      } else {
        this.handleLogout();
      }
    },
    handleLogout() {
      localStorage.removeItem('user-token');
      this.isAuthenticated = false;
      this.$router.push({ name: 'UserLogin' });
    }
  }
};
</script>

<style scoped>
*, html {
  padding: 0;
  margin: 0;
}

.tac {
  display: flex;
  height: 100vh;
}

.sidebar-menu {
  height: 100vh;
  background-color: #1F2937;
  color: white;
  overflow: auto;
}

.righ {
  height: 100vh;
  padding: 20px;
}

.user {
  width: 100%;
  height: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #19A9FD 0%, rgba(0, 0, 0, 0) 100%);
}

.user-div {
  margin: 0 auto;
  text-align: center;
}

.user-div p {
  font-size: 1.5rem;
  color: #fff;
}

.user-vip {
  width: 9rem;
  height: 1.5rem;
  margin: 0 auto;
  color: #fff;
  background-color: #A4ADB3;
  margin-top: 0.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
}
</style>
