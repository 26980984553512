<template>
  <div class="NewGame">
    <el-header>
        <h1>发布新游戏</h1>
    </el-header>
    <el-form :model="newGame" ref="newGameForm" :rules="rules" label-width="120px" style="max-width: 600px; margin: auto;">
      <el-form-item label="游戏ID" prop="gameid">
        <el-input v-model="newGame.gameid"></el-input>
      </el-form-item>
      <el-form-item label="游戏名称" prop="title">
        <el-input v-model="newGame.title"></el-input>
      </el-form-item>
      <el-form-item label="描述及介绍" prop="descor">
        <el-input type="textarea" v-model="newGame.descor"></el-input>
      </el-form-item>
      <el-form-item label="封面图URL" prop="photo">
        <el-input v-model="newGame.photo"></el-input>
      </el-form-item>
      <el-form-item label="价格">
        <el-input v-model="newGame.price" type="number" placeholder="最多两位小数"></el-input>
      </el-form-item>
      <el-form-item label="销量" prop="sellNum">
        <el-input v-model="newGame.sellNum"></el-input>
      </el-form-item>
      <el-form-item label="上架时间" prop="postTime">
        <el-date-picker v-model="newGame.postTime" type="datetime" placeholder="选择上架时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="状态" prop="types">
        <el-select v-model="newGame.types" placeholder="选择状态">
          <el-option label="正常" value="0"></el-option>
          <el-option label="停售" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="离线账号" prop="username">
        <el-input v-model="newGame.username"></el-input>
      </el-form-item>
      <el-form-item label="离线密码" prop="password">
        <el-input v-model="newGame.password" type="password"></el-input>
      </el-form-item>
      <el-form-item label="分类" prop="category">
        <el-select v-model="newGame.category" placeholder="选择分类">
          <el-option v-for="category in categories" :key="category" :label="category" :value="category"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="D加密情况">
         <el-select v-model="newGame.djiami">
           <el-option label="否" value="0"></el-option>
           <el-option label="是" value="1"></el-option>
         </el-select>
       </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from '../axios';
import { ElMessage } from 'element-plus';

export default {
  name: 'NewGame',
  data() {
    return {
      newGame: {
        gameid: '',
        title: '',
        descor: '',
        photo: '',
        price: '',
        sellNum: '',
        postTime: '',
        types: '0',
        username: '',
        password: '',
        category: '', // 添加 category 字段
        djiami: ''
      },
      categories: ['休闲益智', '动作格斗', '角色扮演', '冒险解谜', '经营模拟', '射击游戏', '恐怖游戏'],
      rules: {
        gameid: [
          { required: true, message: '请输入游戏ID', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请输入游戏名称', trigger: 'blur' }
        ],
        descor: [
          { required: true, message: '请输入描述及介绍', trigger: 'blur' }
        ],
        photo: [
          { required: true, message: '请输入封面图URL', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请输入价格', trigger: 'blur' }
        ],
        sellNum: [
          { required: true, message: '请输入销量', trigger: 'blur' }
        ],
        postTime: [
          { required: true, message: '请选择上架时间', trigger: 'change' }
        ],
        types: [
          { required: true, message: '请选择状态', trigger: 'change' }
        ],
        username: [
          { required: true, message: '请输入离线账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入离线密码', trigger: 'blur' }
        ],
        djiami: [
          { required: true, message: '请选择D加密状态', trigger: 'change' }
        ],
        // category 可以为空，所以不需要添加验证规则
      }
    };
  },
  methods: {
    async submitForm() {
      this.$refs.newGameForm.validate(async (valid) => {
        if (valid) {
          try {
            const token = localStorage.getItem('user-token');
            // Convert postTime to MySQL compatible format
            const formattedPostTime = new Date(this.newGame.postTime).toISOString().slice(0, 19).replace('T', ' ');
  
            const response = await axios.post('/index.php?action=addNewGame', {
              token,
              newGame: JSON.stringify({
                ...this.newGame,
                postTime: formattedPostTime
              })
            });
            if (response.data.success) {
              ElMessage.success('游戏已成功发布');
              this.resetForm();
            } else {
              ElMessage.error(response.data.message);
            }
          } catch (error) {
            console.error('Error adding new game:', error);
            ElMessage.error('发布失败，请稍后重试');
          }
        } else {
          ElMessage.error('表单验证失败');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.newGameForm.resetFields();
    }
  }
};
</script>

<style scoped>
.NewGame {
  padding: 20px;
}
</style>
