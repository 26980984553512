<template>
  <div class="dashboard">
    <div class="controls">
      <div class="card">
        <div class="card-header">
          <span>控制面板</span>
        </div>
        <div class="card-body">
          <el-form>
            <el-form-item label="发布新版本">
              <el-input v-model="newVersion" placeholder="请输入版本号"></el-input>
              <el-input v-model="downloadUrl" placeholder="请输入下载URL"></el-input>
              <el-button @click="releaseNewVersion">发布</el-button>
            </el-form-item>
            <el-form-item label="系统维护">
              <el-switch v-model="isMaintenance"></el-switch>
              <el-button @click="toggleMaintenance">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <div class="carousel-settings">
      <div class="card">
        <div class="card-header">
          <span>轮播图设置</span>
        </div>
        <div class="card-body">
          <el-form>
            <el-form-item label="图片URL">
              <el-input v-model="carousel.image" placeholder="请输入图片URL"></el-input>
            </el-form-item>
            <el-form-item label="标题">
              <el-input v-model="carousel.title" placeholder="请输入标题"></el-input>
            </el-form-item>
            <el-form-item label="描述">
              <el-input v-model="carousel.description" placeholder="请输入描述"></el-input>
            </el-form-item>
            <el-button @click="addCarouselImage">添加</el-button>
          </el-form>
          <el-table :data="carouselImages">
            <el-table-column prop="image" label="图片URL"></el-table-column>
            <el-table-column prop="title" label="标题"></el-table-column>
            <el-table-column prop="description" label="描述"></el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-button @click="removeCarouselImage(scope.$index)" type="danger">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';
import { ElMessage } from 'element-plus';


export default {
  name: 'UserDashboard',
  data() {
    return {
      newVersion: '',
      downloadUrl: '',
      isMaintenance: false,
      carousel: {
        image: '',
        title: '',
        description: ''
      },
      carouselImages: []
    };
  },
  methods: {
    releaseNewVersion() {
      const token = localStorage.getItem('user-token');
      axios.post('/index.php?action=releaseNewVersion', { version: this.newVersion, download_url: this.downloadUrl, token })
        .then(response => {
          this.$message.success('新版本发布成功');
        })
        .catch(error => {
          this.$message.error('新版本发布失败');
        });
    },
    toggleMaintenance() {
      const token = localStorage.getItem('user-token');
      axios.post('/index.php?action=toggleMaintenance', { token })
        .then(response => {
          this.isMaintenance = response.data.newStatus === 1;
          this.$message.success('维护状态更新成功');
        })
        .catch(error => {
          this.$message.error('维护状态更新失败');
        });
    },
    addCarouselImage() {
      const token = localStorage.getItem('user-token');
      axios.post('/index.php?action=addCarouselImage', { ...this.carousel, token })
        .then(response => {
          this.carouselImages.push({ ...this.carousel });
          this.carousel = { image: '', title: '', description: '' };
          this.$message.success('轮播图添加成功');
        })
        .catch(error => {
          this.$message.error('轮播图添加失败');
        });
    },
    removeCarouselImage(index) {
      this.carouselImages.splice(index, 1);
    },
    getMaintenanceStatus() {
      const token = localStorage.getItem('user-token');
      axios.post('/index.php?action=getMaintenanceStatus', { token })
        .then(response => {
          this.isMaintenance = response.data.isMaintenance;
        })
        .catch(error => {
          this.$message.error('获取维护状态失败');
        });
    }
  },
  created() {
    // Fetch carousel images from server when component is created
    const token = localStorage.getItem('user-token');
    axios.post('/index.php?action=getCarouselImages', { token })
      .then(response => {
        this.carouselImages = response.data.carouselImages;
      })
      .catch(error => {
        this.$message.error('获取轮播图失败');
      });

    // Fetch maintenance status when component is created
    this.getMaintenanceStatus();
  }
};
</script>

<style scoped>
.dashboard {
  padding: 20px;
}

.card {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.card-header {
  background-color: #f5f5f5;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.card-body {
  padding: 10px;
}
</style>
