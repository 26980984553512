<template>
  <el-container>
    <el-header>
      <h1>仪表盘</h1>
      <el-button type="primary" @click="logout" style="float: right;">退出登录</el-button>
    </el-header>

    <el-main>
      <el-row :gutter="20">
        <el-col :span="6" v-for="(value, key) in stats" :key="key">
          <el-card>
            <h2 style="margin-bottom: 30px;">{{ getStatLabel(key) }}</h2>
            <p>{{ value }}</p>
          </el-card>
        </el-col>
      </el-row>

      <el-card style="margin-top: 20px;">
        <h2 style="margin-bottom: 20px;">公告信息</h2>
        <el-table :data="announcements" style="width: 100%">
          <el-table-column prop="username" label="发布人" width="120"></el-table-column>
          <el-table-column prop="pushtime" label="发布时间" width="180"></el-table-column>
          <el-table-column prop="info" label="内容"></el-table-column>
        </el-table>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from '../axios';
import { ElMessage } from 'element-plus';

export default {
  name: 'HomePage',
  setup() {
    const userInfo = ref({});
    const stats = ref({});
    const announcements = ref([]);

    const fetchUserInfo = () => {
      const token = localStorage.getItem('user-token');
      axios.post('/index.php?action=getUserInfos', { token })
        .then(response => {
          if (response.data.code === 200) {
            userInfo.value = response.data.user;
          } else {
            ElMessage.error(response.data.message);
          }
        })
        .catch(error => {
          console.error('Error fetching user info:', error);
        });
    };

    const fetchStats = () => {
      const token = localStorage.getItem('user-token');
      axios.post('/index.php?action=getStats', { token })
        .then(response => {
          if (response.data.code === 200) {
            stats.value = response.data.stats;
          } else {
            ElMessage.error(response.data.message);
          }
        })
        .catch(error => {
          console.error('Error fetching stats:', error);
        });
    };

    const fetchAnnouncements = () => {
      const token = localStorage.getItem('user-token');
      axios.post('/index.php?action=getAnnouncements', { token })
        .then(response => {
          if (response.data.success) {
            announcements.value = response.data.announcements;
          } else {
            ElMessage.error(response.data.message);
          }
        })
        .catch(error => {
          console.error('Error fetching announcements:', error);
        });
    };

    const logout = () => {
      localStorage.removeItem('user-token');
      window.location.href = '/login';
    };

    const getStatLabel = (key) => {
      const labels = {
        totalCreateCards: '累计制卡次数',
        totalUsedCodes: '累计兑换码使用人次',
        todayCreateCards: '当天制卡次数',
        todayUsedCodes: '当天兑换码使用人次',
        todayRegisterUsers: '当天注册用户数量',
        totalRegisterUsers: '累计注册用户数量',
        totalBannedUsers: '累计封禁用户数量',
        totalPublishedGames: '累计发布游戏数量',
        totalOrders: '累计订单数量',
        todayOrdersPeople: '当天下单人次',
        todayOrdersOK: '当天付款人次',
        todayOrdersPrice: '已付订单总额',
        totalBannedCodes: '累计封卡次数'
      };
      return labels[key] || key;
    };

    onMounted(() => {
      fetchUserInfo();
      fetchStats();
      fetchAnnouncements();
    });

    return {
      userInfo,
      stats,
      announcements,
      fetchUserInfo,
      fetchStats,
      fetchAnnouncements,
      logout,
      getStatLabel,
    };
  }
};
</script>

<style scoped>
.el-container {
  padding: 20px;
}
.el-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.el-main {
  padding: 20px;
}
.el-card {
  margin-bottom: 20px;
}
</style>
